// store/index.js

import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [createPersistedState()],
  state: {
    groups: null,
    ideas: [], // Add this line
    currentIdeas: []
  },
  mutations: {
    setGroups(state, groups) {
      state.groups = groups;
    },
    setIdeas(state, ideas) { // Add this mutation
      state.ideas = ideas;
    },
    setCurrentIdeas(state, currentIdeas) { // Add this mutation
      state.currentIdeas = currentIdeas;
    }
  },
  actions: {
    updateGroups({ commit }, groups) {
      commit('setGroups', groups);
    },
    updateIdeas({ commit }, ideas) { // Add this action
      commit('setIdeas', ideas);
    },
    updateCurrentIdeas({ commit }, currentIdeas) { // Add this action
      commit('setCurrentIdeas', currentIdeas);
    }
  },
  modules: {
    // Add any additional modules if needed
  }
});
