import { createRouter, createWebHistory } from 'vue-router';
import SetupPage from '../components/SetupPage.vue';
import SessionPage from '../components/SessionPage.vue';
import ResultsPage from '../components/ResultsPage.vue';

const routes = [
  { path: '/', name: 'SetupPage', component: SetupPage },
  { path: '/session', name: 'SessionPage', component: SessionPage, props: true },
  { path: '/results', name: 'ResultsPage', component: ResultsPage, props: true }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
