<template>
  <div class="page-container">
    <router-link class="return-link" :to="{ name: 'SetupPage' }">
      &#8592; Return to Setup
    </router-link>
    <div class="container">
    <table class="material-table">
      <thead>
        <tr>
          <th>{{ groups.group1.name}}</th>
          <th>{{ groups.group2.name}}</th>
          <th>{{ groups.group3.name}}</th>
          <th>Idea</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="idea in ideas" :key="idea.id">
          <td>{{ idea.cards[0].content.name }} - {{ idea.cards[0].content.description }}</td>
          <td>{{ idea.cards[1].content.name }} - {{ idea.cards[1].content.description }}</td>
          <td>{{ idea.cards[2].content.name }} - {{ idea.cards[2].content.description }}</td>
          <td>{{ idea.idea }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</template>

<script>
export default {
  name: 'ResultsPage',
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    ideas() {
      return this.$store.state.ideas;
    },
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}


.material-table {
  width: 100%;
  border-collapse: collapse;
  margin: 15px 0;
}

.material-table th,
.material-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.material-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}

.return-link {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #4285f4; /* Google's blue color */
  text-decoration: none; /* Remove default link text decoration */
  font-size: 16px;
}

.return-link:hover {
  color: #2c75f2; /* Change color when hovering over the link */
}

</style>