<!-- Setup.vue -->
<template>
  <div>
    <h2>Setup</h2>
    <div>
      <label for="groupName1">Group Name:</label>
      <input type="text" v-model="group1Name" id="groupName1">
    </div>
    <div>
      <textarea v-model="group1Input" id="group1" rows="5"></textarea>
    </div>
    <div>
      <label for="groupName2">Group Name:</label>
      <input type="text" v-model="group2Name" id="groupName2">
    </div>
    <div>
      <textarea v-model="group2Input" id="group2" rows="5"></textarea>
    </div>
    <div>
      <label for="groupName3">Group Name:</label>
      <input type="text" v-model="group3Name" id="groupName3">
    </div>
    <div>
      <textarea v-model="group3Input" id="group3" rows="5"></textarea>
    </div>
    <button @click="start">Start</button>

    <button @click="clear">Clear Ideas</button>
  </div>
</template>

<script>
export default {
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    ideas() {
      return this.$store.state.ideas;
    },
  },
  data() {
    return {
      group1Name: 'Customer Segment',
      group1Input: 'name\tdescription\n',
      group2Name: 'Situation',
      group2Input: 'red\nblue\ngreen',
      group3Name: 'Attribute',
      group3Input: '1\n2\n3'
    };
  },
  methods: {
    clear() {
      this.$store.dispatch('updateIdeas', []);
      this.$store.dispatch('updateCurrentIdeas', []);
    },
    start() {
      const group1Array = this.group1Input.split('\n').map(line => {
      const [name, description] = line.trim().split('\t');
      return { name, description };
    });

    const group2Array = this.group2Input.split('\n').map(line => {
      const [name, description] = line.trim().split('\t');
      return { name, description };
    });

    const group3Array = this.group3Input.split('\n').map(line => {
      const [name, description] = line.trim().split('\t');
      return { name, description };
    });
      
      const groups = {
        group1: {
          name: this.group1Name,
          items: group1Array
        },
        group2: {
          name: this.group2Name,
          items: group2Array
        },
        group3: {
          name: this.group3Name,
          items: group3Array
        }
      };

      this.$store.dispatch('updateGroups', groups);
      this.$router.push({ name: 'SessionPage' });
    }
  }
};
</script>
