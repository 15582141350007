<template>
  <div class="page-container">
    <router-link class="return-link" :to="{ name: 'SetupPage' }">
      &#8592; Return to Setup
    </router-link>
    <h1>{{ timer }} seconds</h1>
    <div class="card-container">
      <div v-for="(item, index) in randomItems" :key="index" class="card" :class="flipAnimation">
        <div class="card-content" :class="{ changeAnimation: animationActive}">
          <h4>{{ item.name }}</h4>
          <h3>{{ item.content.name }}</h3>
          <p>{{ item.content.description }}</p>
        </div>
      </div>
    </div>
    <button @click="next">Next</button>
    <button @click="finish">Finish</button>

    <div class="idea-input">
      <label for="idea">Idea:</label>
      <input id="idea" v-model="newIdea" type="text" />
      <button @click="addIdea">Add Idea</button>
    </div>

    <div class="ideas">
      <h2>Ideas:</h2>
      <ul>
        <li v-for="(idea, index) in currentIdeas" :key="index">
          <p>{{ idea }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    groups() {
      return this.$store.state.groups;
    },
    ideas() {
      return this.$store.state.ideas;
    },
  },
  data() {
    return {
      randomItems: [],
      timer: 30,
      intervalId: null,
      flipAnimation: 'flip',
      animationActive: false,
      newIdea: '',
      currentIdeas: [],
    };
  },
  mounted() {
    this.startCountdown();
    this.generateRandomItems();
  },
  beforeUnmount() {
    this.stopCountdown();
  },
  methods: {
    addIdea() {
    const idea = {
      cards: this.randomItems,
      idea: this.newIdea,
    };
    this.currentIdeas.push(idea);
    this.ideas.push(idea)
    this.$store.dispatch('updateCurrentIdeas', this.currentIdeas); // Add this line
    this.$store.dispatch('updateIdeas', this.ideas); // Add this line
    this.newIdea = '';
  },
    generateRandomItems() {
      if (this.groups) {
        this.randomItems = Object.values(this.groups).map(group => {
          const randomIndex = Math.floor(Math.random() * group.items.length);
          return {name: group.name, content: group.items[randomIndex]};
        });
      }
    },
    startCountdown() {
    // Stop the current countdown
    this.stopCountdown();
    // Reset the timer
    this.timer = 30;
      this.intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      clearInterval(this.intervalId);
    },
    next() {
      this.currentIdeas = [];
      this.flipAnimation = this.flipAnimation === 'flip' ? 'flipInverse' : 'flip';
      this.animationActive = true;
      setTimeout(() => {
        this.animationActive = false;
      }, 1000); 
      this.startCountdown();
      this.generateRandomItems();
    },
    finish() {
      this.$router.push({ name: 'ResultsPage' });
    }
  }
};
</script>

<style scoped>

.return-link {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #4285f4; /* Google's blue color */
  text-decoration: none; /* Remove default link text decoration */
  font-size: 16px;
}

.return-link:hover {
  color: #2c75f2; /* Change color when hovering over the link */
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.card-container {
  display: flex;
}

.card-content {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.card-content.changeAnimation {
  animation: changeAnimation 1s;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 240px;
  height: 335px;
  border: 1px solid #ccc;
  border-radius: 19px;
  padding: 10px;
  margin-right: 10px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

@keyframes changeAnimation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flip {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

@keyframes flipInverse {
  from {
    transform: rotateY(360deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

.card.flip {
  animation: flip 1s;
}

.card.flipInverse {
  animation: flipInverse 1s;
}

button {
  background-color: #4285f4; /* Google's blue color */
  color: white; /* Text color */
  border: none; /* Remove default button border */
  padding: 10px 24px; /* Padding */
  text-align: center; /* Center the text */
  text-decoration: none; /* Remove default button text decoration */
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; /* Change cursor to pointer when hovering over the button */
  border-radius: 4px; /* Rounded corners */
  transition-duration: 0.4s; /* Transition effect duration */
}

button:hover {
  background-color: #2c75f2; /* Change background color when hovering over the button */
  color: white;
}

</style>
